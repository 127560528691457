import React, { Component, ReactNode, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  to: string
  text?: string
  lineHeight?: string
  children?: ReactNode
}

export default class Link extends Component<Props, {hover: boolean}> {

  constructor(props: Props) {
    super(props)
    this.state = { hover: false }
  }

  private setHover(hover : boolean) { this.setState({hover: hover}) }

  render(): ReactElement<ReactNode> {
    const style = this.getStyle()
    return (
      <div onMouseEnter={() => this.setHover(true)} onMouseLeave={() => this.setHover(false)}>{
        this.props.to.startsWith("http://") || this.props.to.startsWith("https://") ?
          <a href={this.props.to} target="_blank" rel="noopener noreferrer" style={style}>
            {this.props.text || this.props.children}
          </a> :
          <NavLink to={this.props.to} style={style}>
            {this.props.text || this.props.children}
          </NavLink>
      }</div>
    )
  }

  private getStyle(): any {
    return {
      color: this.state.hover ? "#00f" : "#000",
      textDecoration: "none",
      lineHeight: this.props.lineHeight || "normal" 
    }
  }
}