import React, { Component, ReactNode, ReactElement } from 'react'

export default class Email extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
        <span style={{fontSize: "3vmin"}}>
          <span style={{color: "#007"}}>&#106;&#117;&#108;&#105;&#101;&#046;&#097;&#100;&#108;&#097;&#114;&#100;</span>
            <span style={{color: "#d00"}}>22</span>
            <span style={{color: "#007"}}>&#064;&#103;&#109;&#97;&#105;&#108;&#046;&#099;&#111;&#109;</span>
        </span>
    )
  }
}