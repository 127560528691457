export default class Work {

    public readonly name: string
    public readonly section: string
    public readonly sold: boolean

    constructor(
        name: string, 
        section: string, 
        sold: boolean = false) {
        this.name = name
        this.section = section
        this.sold = sold
    }
}