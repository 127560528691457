import React, { Component, ReactNode, ReactElement } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import HomePage from './homePage'
import Biography from './biography'
import Gallery from './gallery'
import Column from './column'
import WildArtTrail from './wildArtTrail'

export default class PageContent extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
      <Column fitWidth={true} marginTop="6vmin">
        <Routes>
          <Route path="/home" element={<HomePage/>}/>
          <Route path="/wildArtTrail" element={<WildArtTrail/>}/>
          <Route path="/gallery/harbours" element={<Gallery section="harbours"/>} />
          <Route path="/gallery/landscapes" element={<Gallery section="landscapes"/>} />
          <Route path="/gallery/italy" element={<Gallery section="italy"/>} />
          <Route path="/gallery/cities" element={<Gallery section="cities"/>} />
          <Route path="/biography" element={<Biography/>} />
          <Route path="/" Component={() => <Navigate to="/home" />}/>
        </Routes>
      </Column>
    )
  }
}
