import React, { Component, ReactNode, ReactElement } from 'react'

export default class Row extends Component<{
    align?: string
    justify?: string
    fitHeight?: boolean
    fitWidth?: boolean
    wrap?: boolean
    children?: ReactNode
}, {}> {
    
    render(): ReactElement<ReactNode> {
        return (
            <div style={{
                display: "flex",
                flexFlow: "row " + (this.props.wrap ? "wrap" : "nowrap"),
                justifyContent: this.props.justify || "center",
                alignItems: this.props.align || "center",
                width: (this.props.fitWidth ? "100%" : "auto"),
                height: (this.props.fitHeight ? "100%" : "auto"),
                margin: "0"
            }}>
                {this.props.children}
            </div> 
        )
    }
}