import React, { Component, ReactNode, ReactElement } from 'react'
import Row from './row'
import Link from './link'
import Works from './works'

interface Props {}

export default class Menu extends Component<Props, {}> {

  private readonly gallerySections: Array<string>
  private readonly keys : Array<string>

  constructor(props: Props) {
    super(props)
    this.gallerySections = Works.Instance.getSections()
    this.keys = ['home']
        .concat(this.gallerySections)
        .concat(['biography'])
  }

  private menuItem(key: string, index: number): ReactElement<ReactNode> {
    const toPrefix = index < 1 || index > this.gallerySections.length ? '' : 'gallery/'
    const to = `/${toPrefix}${key}`
    return (
      <div key={index} style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "1vmin",
        fontFamily: "Arial Narrow"
        }}>
        <style children=".active { font-weight: bold; }" />
        <Link to={to} lineHeight="100vh">
          <span style={{fontSize: "2.5vmin", verticalAlign: "middle"}}>{key}</span>
        </Link>
      </div>
    )
  }

  render(): ReactElement<ReactNode> {
    return (
      <Row fitWidth justify="space-evenly">
        {this.keys.map((key, index) => this.menuItem(key, index))}
      </Row>
    )
  }
}