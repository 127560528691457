import React, { Component, ReactNode, ReactElement } from 'react'
import Row from './row'
import Column from './column'
import Text from './text'
import Email from './email'
import Caption from './caption'
import ImageHolder from './imageHolder'
import Heading from './heading'
import Link from './link'

export default class HomePage extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
        <Column>
          <Row align="flex-start" justify="flex-start" fitWidth={true}>
            <Column>
              <ImageHolder altOptimized image="FishermansCottages" height={53} />
            </Column>
            <Column marginLeft="4vmin">
              <Row fitWidth justify="center">
                <Column align="flex-start">
                  <Text text={`
                      I create unique, clay-collage paintings that produce a tactile, 3D effect.
                      Using colour, I create works that produce a stong emotional response to a place.
                  `}/>
                  <Text text={`
                      Do you have a favourite place you would like commissioned in clay?
                      I am happy to discuss your requirements without obligation and welcome enquires by email:
                  `}/>
                <Email/>
                </Column>
              </Row>
              <Row fitWidth justify="space-between" align="flex-end">
                <Column align="flex-start">
                  <Heading text="Current Venues"/>
                  <div style={{fontSize: "2.5vmin"}}>
                  <Link text="Dorset Wild Art Trail" to="/wildArtTrail"/>
                  <Link text="Dorset Art Weeks" to="https://www.dorsetartweeks.co.uk/directory/list/venue/6651ead7-f9b2-4b6e-8869-0331b9c46bc2"/>
                  <Link text="Fowey River Gallery, Fowey" to="https://www.foweyrivergallery.co.uk/stock.php?artist=Adlar66414&session=2459252"/>
                  <Link text="Picture House Gallery, Padstow" to="https://www.thepicturehousegallery.co.uk/julie-adlard"/>
                  <Link text="Dorset Visual Artists" to="https://dorsetvisualarts.org/artist-details/julie-adlard"/>
                  </div>
                </Column>
                <Column>
                  <ImageHolder alignBottom image="Artist" unoptimized={true} height={20}/>
                  <Caption text="Julie Adlard: Artist" fontSize="1.5vmin" />
                </Column>
              </Row>
            </Column>
          </Row>
      </Column>
  )
  }
}