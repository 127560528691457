import Work from './work'

export default class Works {

    private readonly works : Array<Work>
    private readonly worksBySection: Map<string, Array<Work>>
    private readonly sections: Array<string>

    public static readonly Instance : Works = new Works()

    private constructor() {
        this.worksBySection = new Map()
        this.sections = []
        this.works = [
            new Work("Fowey", "harbours", true),
            new Work("Fowey2", "harbours", true),
            new Work("LowTideAtPortlooe", "harbours", false),            
            new Work("PadstowHarbour2", "harbours", true),
            new Work("FoweyRiverFront", "harbours", true),
            new Work("DaysGoneBy", "harbours", true),
            new Work("BoatsAtLowTide", "harbours", true),
            new Work("OldBoats", "harbours", false),            
            new Work("StaithesHarbour", "harbours", true),
            new Work("CatchOfTheDay", "harbours", true),
            new Work("Portloe",  "harbours", true),
            new Work("PrimeCellars", "harbours"),            
            new Work("HayOnWye", "harbours", true),
            new Work("FishermansCottages", "harbours", true),
            new Work("HarbourBoats", "harbours", false),
            new Work("PenberthCove",  "harbours", true),
            new Work("Clovelly", "harbours", false),
            new Work("HousesAtPittenweem", "harbours", false),
            new Work("MistRollingInFromTheSea", "harbours", true),
            new Work("StIvesBoats", "harbours", true),
            new Work("TheRaceClifton", "harbours", false),
            new Work("WinterFields", "landscapes", false),
            new Work("SecondDustingOfSnow", "landscapes" , true),
            new Work("SummerMeadow", "landscapes", false),
            new Work("PatchworkFields", "landscapes", false),
            new Work("SailingBoatsAtRock", "landscapes", false),
            new Work("TheHarvest", "landscapes", false),
            new Work("FieldsAtTurville", "landscapes" , true),
            new Work("YellowFieldsForever", "landscapes" , true),
            new Work("BlueGondolas",  "italy", true),
            new Work("VeniceDoorway",  "italy", true),
            new Work("RioMaggiore",  "italy", true),
            new Work("GrandCanal", "italy", true),
            new Work("GrandCanal2", "italy", false),
            new Work("GrandCanal3", "italy", true),
            new Work("ReflectionsOfVenice", "italy", false),
            new Work("SunsetVeniceCanal", "italy", false),
            new Work("VeniceAlleyway", "italy", false),
            new Work("ManarolaItaly",  "italy", true),
            new Work("BostonHarbour",  "cities", true),
            new Work("GlitteringLondonLights",  "cities", true),
            new Work("LondonAerial",  "cities", true),
            new Work("TheTower",  "cities", true),
        ]
        this.works.forEach(work => {
            let sectionWorks = this.worksBySection.get(work.section)
            if(sectionWorks === undefined) {
                sectionWorks = []
                this.worksBySection.set(work.section, sectionWorks)
            }
            sectionWorks.push(work)
            if(this.sections.find(section => section === work.section) === undefined) {
                this.sections.push(work.section)
            }
        })
    }

    getSections(): Array<string> { 
        return this.sections
    }

    inSection(section : string): Array<Work> {
        const works = this.worksBySection.get(section)
        if(works === undefined) {
            throw new Error(`There are no works in section: ${section}`)
        }
        return works
    }
}
