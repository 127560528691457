import React, { Component, ReactNode, ReactElement } from 'react'

export default class Column extends Component<{
    align?: string
    justify?: string
    fitHeight?: boolean
    fitWidth?: boolean
    width?: string
    marginLeft?: string
    marginRight?: string
    marginTop?: string
    marginBottom?: string
    children?: ReactNode
}, {}> {
    
    render(): ReactElement<ReactNode> {
        return (
            <div style={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: this.props.justify || "center",
                alignItems: this.props.align || "center",
                width: this.props.width || (this.props.fitWidth ? "100%" : "auto"),
                height: (this.props.fitHeight ? "100%": "auto"),
                marginLeft: this.props.marginLeft || "0",
                marginRight: this.props.marginRight || "0",
                marginTop: this.props.marginTop || "0",
                marginBottom: this.props.marginBottom || "0"
            }}>
                {this.props.children}
            </div> 
        )
    }
}