import React, { Component, ReactNode, ReactElement } from 'react'

export default class SimpleText extends Component<{text: string;}, {}> {
  
  render(): ReactElement<ReactNode> {
    return (
      <span style={{display: 'block'}}>
        {this.props.text}
      </span>
    )
  }
}