import React, { Component, ReactNode, ReactElement } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ImageHolder from './imageHolder'
import Work from './work'
import Works from './works'
import Row from './row'
import Column from './column'

interface Props {
    section: string
}

export default class Gallery extends Component<Props, { imageIndex: number, viewerOpen: boolean }> {

    private readonly works: Works

    constructor(props: Props) {
        super(props)
        this.works = Works.Instance
        this.state = { 
            imageIndex: 0, 
            viewerOpen: false
        }
    }

    render(): ReactElement<ReactNode> {
        const works = this.getWorks()
        const index = this.state.imageIndex > works.length - 1 ? 0 : this.state.imageIndex
        const nextIndex = index >= works.length - 1 ? 0 : index + 1
        const prevIndex = index <= 0 ? works.length - 1 : index - 1
        const work = works[index]
        const nextWork = works[nextIndex]
        const prevWork = works[prevIndex]
        const source = this.getWorkSource(work)
        const nextSource = this.getWorkSource(nextWork)
        const prevSource = this.getWorkSource(prevWork)
        const title = this.getTitle(work)
        const columnWidthText = this.getColumnWidthText()
        const columnIndexes = this.getColumnIndexes()
        const workHeight = this.getWorkHeight()
        return (
            this.state.viewerOpen ?
            <Lightbox 
                mainSrc={source} 
                nextSrc={nextSource}
                prevSrc={prevSource}
                onMoveNextRequest={() => this.setImageIndex(nextIndex)}
                onMovePrevRequest={() => this.setImageIndex(prevIndex)}
                imageTitle={title}
                onCloseRequest={() => this.setState({ viewerOpen: false })}/>
            :
            <Row align="flex-start" justify="center" fitWidth fitHeight wrap>{
                columnIndexes.map(columnIndex => (
                    <Column key={columnIndex} align="center" justify="center" width={columnWidthText} fitHeight>{
                        this.workElements(works, columnIndex, workHeight)
                    }</Column>                    
                ))
            }</Row>
        )
    }

    private getWorks(): Array<Work> {
        const section = this.props.section
        return this.works.inSection(section)
    }

    private getColumnIndexes(): Array<number> {
        const numColumns = this.getNumColumns()
        return new Array(numColumns)
        .fill(0)
        .map((_, i) => i)
    }

    private getColumnWidthText(): string {
        const numColumns = this.getNumColumns()
        const columnWidth = 100.0 / numColumns
        return `${columnWidth}%`
    }

    private getWorkHeight(): number {
        const numColumns = this.getNumColumns()
        const margin = this.getMargin()
        const totalSize = 87.6
        return totalSize / numColumns - margin
    }

    private getMargin(): number {
        return 0.8
    }

    private getMarginText(): string {
        const margin = this.getMargin()
        return `${margin}vmin`
    }

    private getNumColumns(): number {
        const numWorks = this.getWorks().length
        const minNumWorks = [0, 5, 11]
        for (let minNumWorkIndex = 0; minNumWorkIndex < minNumWorks.length; minNumWorkIndex++) {
            if(numWorks < minNumWorks[minNumWorkIndex]) {
                return minNumWorkIndex
            }
        }
        return minNumWorks.length
    }

    private getTitle(work: Work): string {
        const soldText = this.getSoldText(work)
        const displayName = work.name.replace(/([A-Z0-9])/g,' $1').substring(1)
        return displayName + soldText
    }

    private getSoldText(work: Work): string {
        return work.sold ? " (sold)" : ""
    }

    private getWorkSource(work: Work): any {
        return require(`../img/${work.name}.jpg`)
    }

    private setImageIndex(index: number): void {
        this.setState({imageIndex: index, viewerOpen: true})
    }

    private workElements(
        works: Array<Work>, 
        columnIndex: number, 
        workHeight: number
    ): React.JSX.Element[] {
        const margin=this.getMarginText()
        const numColumns = this.getNumColumns()
        return works.map((work, index) => (
            index % numColumns == columnIndex ? 
            <div key={index} style={{cursor: "pointer"}} onClick={() => this.setImageIndex(index)}>
                <ImageHolder image={work.name} height={workHeight} margin={margin}/>
            </div>
            : <span key={index}></span>
        ))
    }
}
