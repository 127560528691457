import React, { Component, ReactNode, ReactElement } from 'react'
import Row from './row'
import Column from './column'
import ImageHolder from './imageHolder'

export default class WildArtTrail extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
        <Column>
          <Row align="flex-start" justify="flex-start" fitWidth={true}>
            <Column>
              <ImageHolder image="DorsetWildArtTrail" height={75} />
            </Column>
          </Row>
      </Column>
  )
  }
}