import React, { Component, ReactNode, ReactElement } from 'react'
import preval from 'preval.macro'

export default class BuildTime extends Component<{ show?: boolean }, {}> {

    render(): ReactElement<ReactNode>  {
        const time = preval`module.exports = new Date().toUTCString();`
        return (
            <span id='buildTime' key='buildTime' style={{ 
                textAlign: 'center',
                fontSize: '1vw',
                fontStyle: 'italic',
                width: '100%',
                margin: 0, 
                padding: 0,
                cursor: 'inherit',
                display: this.props.show? 'auto' : 'none'
            }}>
                {'Build time: ' + time}
            </span>
        )
    }
}
