import React, { Component, ReactNode, ReactElement } from 'react'

export default class Caption extends Component<{
   text: string
   fontSize? : string
   marginLeft?: string
}, {}> {

    render(): ReactElement<ReactNode> {
        return (
                <span style={{
                    fontStyle: "italic",
                    fontSize: this.props.fontSize || "3vmin", 
                    marginTop: "1vmin",
                    marginLeft: this.props.marginLeft || "0"
                }}>
                    {this.props.text}
                </span>
        )
    }
}