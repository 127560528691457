import React, { Component, ReactNode, ReactElement } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import Menu from './components/menu'
import Column from './components/column'
import Title from './components/title'
import BuildTime from './components/buildTime'
import PageContent from './components/pageContent'
import Row from './components/row'
import FeatureFlag from './featureFlag'

export default class App extends Component<{}, {}> {

  constructor(props : {}) {
    super(props)
  }

  render(): ReactElement<ReactNode> {
    return (
      <Router>
        <Column align="flex-start" justify="flex-start" width="90vmin">
            <style children={`
              body {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10vmin 0 0 0;
                padding: 0;
              }
              a,span {
                user-select: none;
              }
            `} />
            <BuildTime show={new FeatureFlag('showBuildTime').isEnabled()}/>
            <Row fitWidth={true}>
              <Title />
              <Menu />
            </Row>
            <PageContent />
        </Column>
      </Router>
    )
  }
}
