import React, { Component, ReactNode, ReactElement } from 'react'
import Spinner from './spinner'

interface Props {
    image: string
    height: number
    unoptimized?: boolean
    altOptimized?: boolean
    margin?: string
    alignBottom?: boolean
}

interface State {
    aspectRatio: number
    viewportAspectRatio: number
}

export default class ImageHolder extends Component<Props, State> {

    private lastSource: string

    constructor(props: Props) {
        super(props)
        this.state = {
            aspectRatio: -1,
            viewportAspectRatio: window.innerWidth / window.innerHeight
        }
        this.lastSource = ""
    }

    private resized = () => {
        this.setState({
            aspectRatio: this.state.aspectRatio,
            viewportAspectRatio: window.innerWidth / window.innerHeight
        })
    }

    componentDidMount() {
        window.addEventListener("resize", this.resized)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resized)
    }

    render(): ReactElement<ReactNode> {
        const image = this.props.unoptimized ? this.props.image 
        : (this.props.altOptimized? `${this.props.image}-Opt2` : `${this.props.image}-Opt`)
        const source = require(`../img/${image}.jpg`)
        if(source !== this.lastSource) {
            const image = new Image()
            image.onload = () => {
                this.setState({ aspectRatio: image.width / image.height })
            }
            image.src = source
            this.lastSource = source
        }
        if(this.state.aspectRatio < 0) {
            return (
                <div style={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center"
                }}>
                    <Spinner/>
                </div>
            )
        }
        const width = this.props.height * this.state.aspectRatio
        return (
            <div style={{
                display: "flex",
                backgroundPosition: this.props.alignBottom? "bottom center" : "top center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${source})`, 
                height: `${this.props.height}vmin`, 
                width: `${width}vmin`,
                margin: this.props.margin || "0"
            }}/>
        )
    }
}
