import React, { Component, ReactNode, ReactElement } from 'react'

export default class Spinner extends Component<{}, {}> {

    render(): ReactElement<ReactNode> {
        return (
            <div>
                <style children="@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }" />
                <div style={{
                    border: "4vmin solid #eee",
                    borderTop: "4vmin solid #444",
                    borderRadius: "70%",
                    width: "12vmin",
                    height: "12vmin",
                    animation: "spin 2s linear infinite"
                }}/>
            </div>
        )
    }
}
