import React, { Component, ReactNode, ReactElement } from 'react'

export default class Text extends Component<{text: string}, {}> {
  
  render(): ReactElement<ReactNode> {
    return (
      <span style={{display:"block",marginBottom:"1vmin",fontSize:"3vmin",width:"100%"}}>
        {this.props.text}
      </span>
    )
  }
}