import React, { Component, ReactNode, ReactElement } from 'react'

export default class Title extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
      <span style={{fontSize:"3vmin",width:"18%",color: "#007", fontStyle: "italic"}}>
        JULIE ADLARD
      </span>
    )
  }
}