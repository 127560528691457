import React, { Component, ReactNode, ReactElement } from 'react'
import SimpleText from './simpleText'
import Text from './text'
import Link from './link'
import Heading from './heading'

export default class Biography extends Component<{}, {}> {

  render(): ReactElement<ReactNode> {
    return (
    <div>
        <Text text={`
          I studied Combined Craft Design at South Bank University and Advanced Ceramics and Painting at Kingston College. 
          My inspiration comes from travelling and I love working on both landscapes and harbours. 
          My work is bright and bold and has a naivety that makes you want to smile. 
          I am interested in surface texture and work in mixed media, using clay and fabrics to create collage paintings. 
          The shapes are cut from clay, adhered to the canvas and then over-painted using acrylics and glazes to give vibrancy to the work. 
          This brings the subject to life, making the painting tactile and much more than a two dimensional object.
        `}/>
        <div style={{fontSize: "3vmin"}}>
          <Heading text="2020-2023"/>
          <Link text="Artistry Galley, Henley-on-Thames" to="https://www.artistryhenley.com"/>
          <Link text="Artists at Goring, Goring-on-Thames" to="https://www.artistsatgoring.co.uk"/>
          <Link text="Tiny Gallery, Henley-on-Thames" to="https://tinygallery.co.uk/collections/julie-adlard"/>
          <Link text="Tutor at Micklems Farm, Berkshire" to="https://www.micklemsfarm.com/packages-1/advanced-clay-collage-harbour-scene"/>
          <Link text="Fowey River Gallery Solo Show" to="https://www.foweyrivergallery.co.uk"/>
          <Link text="Wychwood Gallery, Deddington" to="https://www.picturehousegalleries.co.uk"/>
          <Heading text="2019"/>
          <Link text="Fowey River Gallery Solo Show" to="https://www.foweyrivergallery.co.uk"/>
          <Link text="Ytene Gallery, Brockenhurst" to="http://www.ytenegallery.co.uk"/>
          <Link text="Artists at Goring, Goring-on-Thames" to="https://www.artistsatgoring.co.uk"/>
          <Heading text="2018"/>
          <Link text="Henley Arts Trail, Henley-on-Thames" to="http://www.henleyartstrail.com"/>
          <Link text="No Naked Walls, Chertsey and Bramley" to="https://www.nonakedwalls.co.uk"/>
          <Link text="Artists at Goring, Goring-on-Thames" to="https://www.artistsatgoring.co.uk"/>          
          <Link text="Ytene Gallery, Brockenhurst" to="http://www.ytenegallery.co.uk"/>
          <Heading text="2017"/>
          <Link text="byGillian Gallery, Bourne End" to="http://www.bygillian.com"/>
          <SimpleText text="Art for Arts Sake, Twyford"/>
          <Heading text="2016"/>
          <Link text="Art In Action" to="http://www.artinaction.org.uk"/>
          <Link text="Group Summer Exhibition at Gallery 55" to="http://galleryfiftyfive.co.uk"/>
          <Link text="SOTA Gallery, Witney" to="http://www.sotagallery.co.uk"/>
          <Link text="Gallery Fifty Five, Hartley Wintney" to="http://galleryfiftyfive.co.uk"/>
          <Link text="Epoch 3, Reading" to="https://www.facebook.com/pages/epoch-3/440432119468855"/>          
          <Heading text="2015"/>
          <Link text="Henley Festival" to="http://www.henley-festival.co.uk/programme/visual-arts/?vId=1178#1369"/>
          <Link text="Cookham and Maidenhead Arts Trail" to="http://camat.org.uk"/>
          <Link text="The Art House, Wokingham" to="http://wokyarthouse.weebly.com"/>          
          <Heading text="2014"/>
          <SimpleText text="The Blue Duck Gallery, Caversham"/>
          <Link text="The Gallery at Ice, Windsor" to="http://www.thegalleryatice.co.uk"/>
          <Link text="Cookham and Maidenhead Arts Trail" to="http://camat.org.uk"/>
          <Link text="No Naked Walls, Chertsey and Bramley" to="http://www.nonakedwalls.co.uk"/>          
          <Heading text="Earlier"/>
          <Link text="Windsor Contemporary Art Fair, Windsor" to="http://www.windsorcontemporaryartfair.co.uk"/>
          <Link text="The Saturday Gallery, Wokingham" to="http://saturdaygallery.wordpress.com"/>
          <Link text="The Old Fire Station Gallery, Henley-on-Thames" to="https://www.henleytowncouncil.gov.uk/The-Old-Fire-Station-Gallery.aspx"/>          
          <Link text="European Group Exhibition, Norden Farm, Maidenhead" to="http://nordenfarm.org"/>
          <SimpleText text="Elizabeth House Gallery, Cookham"/>
          <SimpleText text="Parish Centre, Cookham"/>
          <SimpleText text="Neville Johnson Exhibition, Gerrards Cross"/>
          <SimpleText text="Art on the Street, Maidenhead"/>
          <SimpleText text="Art House International Womens Week Exhibition, London"/>
          <SimpleText text="New English Art Club Mall Galleries, London"/>
          <SimpleText text="Kingston Art Society, Annual Exhibition, London"/>
          <SimpleText text="The Truman Galley, London"/>
          <SimpleText text="Smith Gallery, Middlesex"/>
          <SimpleText text="Watermans Art Centre, Middlesex"/>
      </div>
    </div>
    )
  }
}